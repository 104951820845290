const modularEmailFunction = function(currentMpViews, locationResults, userFirstName, userFirstLineAddress, userLocation) {
/**Get default email template
 * 1. Get MP ceasefire choice
 * 2. Based on MPs choice filter any options
 * 3. Randomise
 */

//console.log("MODULAR FILE", "=>", currentMpViews)

const unhappyEmotions = ["frustration", "disgust", "anger", "disappointment"]
const happyEmotions = ["empathy", "pride", "joy", "relief", "respect", "gratitude", "appreciation"]
/**Emotion towards the ceasefire */
let emotionSelect = ""
/**Emotion towards the partys position */
let partyEmotionSelect = ""
const ceasefireChoice = currentMpViews.ceasefire_vote

const topicChoice = [
    "Express the following in a completely unique and original way that over 20,000 civilian Palestinians have been killed, which includes an estimated 5,500 children and 120 journalists.",
    "Express in a completely unique and original way the view that humanitarian pauses are not good enough and we urgently need a long lasting ceasefire.",
    "Explain in a completely unique and original way that the United Nations, Amnesty International, Oxfam and Doctors Without Borders have all called for a ceasefire",
    "Question in a completely unique and original way how many more people need to die before our government acts?",
    "Explain in a completely unique and original way that there is no justification for the indiscriminate killing of Palestinian civilian men, women and children for a crime they did not commit",
    "Explain in a completely unique and original way that we are witnessing a genocide taking place in Gaza",
    "Explain in a completely unique and original way how a Palestinian child is killed every 10 minutes"
]

let topicChoiceSelect = []

/**Do all the logic here */
/**Randomise the topics to discuss */
for (var i = 0; i < 3; i++) {
    var rand = topicChoice[Math.floor(Math.random() * topicChoice.length)];
    topicChoiceSelect.push(rand);
  }

 // console.log(topicChoiceSelect)

if(ceasefireChoice === "No"){
    /**NO VOTE PROMPT */
/**Randomise the emotion based on the ceasefire selection */
    
emotionSelect = (unhappyEmotions[(Math.floor(Math.random() * unhappyEmotions.length))]);
partyEmotionSelect = (unhappyEmotions[(Math.floor(Math.random() * unhappyEmotions.length))]);
//console.log(emotionSelect, partyEmotionSelect)

    const againstPrompt = `Write a completely unique email without a subject line to ${currentMpViews.mp} expressing ${emotionSelect} towards their voting against a cease fire in Gaza on the 15th of November 2023.
    Do not mention that you are a member of the Party.
    Write the email in a way that it is personal and not similar that any other generated email.

    Make sure to sign the email off using ${userFirstName} and ${userFirstLineAddress} and ${userLocation}

Cover the following points randomly:
${topicChoiceSelect[0]}
and
    ${topicChoiceSelect[1]}
    and
    ${topicChoiceSelect[2]}
    
    Always conclude by stating that I will not be voting for any party which does not call for ceasefire.`
    return againstPrompt
}

else if(ceasefireChoice === "Yes"){
    emotionSelect = (happyEmotions[(Math.floor(Math.random() * happyEmotions.length))]);
    partyEmotionSelect = (unhappyEmotions[(Math.floor(Math.random() * unhappyEmotions.length))]);
    //console.log(emotionSelect, partyEmotionSelect)

    const forPrompt = `Write a completely unique email without a subject line to ${currentMpViews.mp} expressing ${emotionSelect} towards their voting for a cease fire in Gaza on the 15th of November 2023. 
    Write the email in a way that it is personal and not similar that any other generated email.

    Do not mention that you are a member of the Party.
    Make sure to sign the email off using ${userFirstName} and ${userFirstLineAddress} and ${userLocation}
  
    Cover the following points randomly: 
    ${topicChoiceSelect[0]}
    and
        ${topicChoiceSelect[1]}
        and
        ${topicChoiceSelect[2]}
        
        Always conclude by stating that I will be voting for your candidacy as you continue to call for a ceasefire.`
                return forPrompt
}

else if (ceasefireChoice === "Abstained"){
    emotionSelect = (unhappyEmotions[(Math.floor(Math.random() * unhappyEmotions.length))]);
    partyEmotionSelect = (unhappyEmotions[(Math.floor(Math.random() * unhappyEmotions.length))]);


    const abstainPrompt = `Write a completely unique email without a subject line to ${currentMpViews.mp} expressing ${emotionSelect} towards them abstaining from voting for a cease fire in Gaza on the 15th of November 2023.
    Do not mention that you are a member of the Party.
    Write the email in a way that it is personal and not similar that any other generated email.

    Make sure to sign the email off using ${userFirstName} and ${userFirstLineAddress} and ${userLocation}
    Make a point similar to this, that abstaining from this vote is an action unto itself and that it is working against a ceasefire.
  
    Cover the following points randomly: 
    ${topicChoiceSelect[0]}
    and
        ${topicChoiceSelect[1]}
        and
        ${topicChoiceSelect[2]}
        
        Always conclude by stating that I will not be voting for any party which does not call for ceasefire.`
                return abstainPrompt

}

    }

export default modularEmailFunction

// ${currentMpViews.party === "Labour" ? `Express ${partyEmotionSelect} at the Labour Party’s position on this.`
// : currentMpViews.party === "Conservative" ? `Express ${partyEmotionSelect} at the Conservative Party’s position on this.`
// : null}

/** FIRST DRAFT OF PROMPT SENT TO CHATGPT
 *  `Write an email without a subject line to ${props.locationResults[0].value.currentRepresentation.member.value.nameDisplayAs} from ${props.userFirstName + " " + props.userLastName} 
 * showing you are frustrated covering the following:1. Express support for Palestinians 2. Argue against the party response to the current conflict 
 * 3. Argue against the response to their personal view on the conflict.`,
    
 */