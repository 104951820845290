
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
  } from "react-share";

function ShareBtns(props){

    return (
        <div className='shareIconsContainer' ref={props.scrollShareRef}>

            <h3>SHARE WITH YOUR FRIENDS AND FAMILY:</h3>

<div className="shareIconsAll">
<div className="shareIcon">
<FacebookShareButton
  url="https://emailsforpalestine.com/?utm_source=facebook&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
  hashtag="emailsforpalestine"
>
  <FacebookIcon borderRadius={"10px"} />
</FacebookShareButton>
</div>


<div className="shareIcon">
<WhatsappShareButton
  url="https://emailsforpalestine.com/?utm_source=whatsapp&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
  title="Call for a ceasefire in Palestine now. Generate a custom email to send to your MP using our AI powered email creator."
  separator="  "
>
  <WhatsappIcon borderRadius={"10px"}/>
</WhatsappShareButton>
</div>

<div className="shareIcon">
<LinkedinShareButton
 url="https://emailsforpalestine.com/?utm_source=linkedin&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
 title="Emails for Palestine"
 summary="Call for a ceasefire in Palestine now. Generate a custom email to send to your MP using our AI powered email creator."
>
  <LinkedinIcon borderRadius={"10px"}/>
</LinkedinShareButton>
</div>

<div className="shareIcon">
<EmailShareButton
url="https://emailsforpalestine.com/?utm_source=email&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
>
  <EmailIcon borderRadius={"10px"}/>
</EmailShareButton>
</div>

<div className="shareIcon">
<TelegramShareButton
  url="https://emailsforpalestine.com/?utm_source=telegram&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
  title="Call for a ceasefire in Palestine now. Generate a custom email to send to your MP using our AI powered email creator."
>
  <TelegramIcon borderRadius={"10px"}/>
</TelegramShareButton>
</div>


<div className="shareIcon">
<TwitterShareButton
 url="https://emailsforpalestine.com/?utm_source=twitter&utm_medium=shared_link&utm_campaign=share_buttons&utm_id=post_send"
 title="Call for a ceasefire in Palestine now. Generate a custom email to send to your MP using our AI powered email creator."
>
  <TwitterIcon borderRadius={"10px"}/>
</TwitterShareButton>
</div>


</div>

</div>
    )
}

export default ShareBtns