import './App.css';
import { useEffect, useState, useRef } from 'react';
import logo from './images/EFP_Logo_BLCK.png';
import Checkbox from 'rc-checkbox';
import mpdata from './data/mpdata.json'
import modularEmailFunction from './modularEmailFunction';
import { FaArrowRight } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { FaRegCopy } from 'react-icons/fa';
import {MdEmail} from  "react-icons/md";

import ShareBtns from './shareBtns';

/** CHAT MP v3.0 */

function App() {

  const [userLocation, setUserLocation] = useState("")
  const [userFirstName, setUserFirstName] = useState("A concerned member of the public")
  const [userFirstLineAddress, setUserFirstLineAddress] = useState("")
  const [locationResults, setLocationResults] = useState([])
  const [currentMpViews, setCurrentMpViews] = useState({})
  const [userEmail, setUserEmail] = useState("")

  const [subjectLine, setSubjectLine] = useState("")
  const [emailTemplate, setEmailTemplate] = useState("")
  const [copyWithoutHTMLForEmail, setCopyWithoutHTMLForEmail] = useState("")
  const [rawEmailDataStore, setRawEmailDataStore] = useState("")
  const [emailToSendTo, setEmailToSendTo] = useState("")
  const [triggerGenerate, setTriggerGenerate] = useState(false)
  const [confirmedPostcode, setConfirmedPostcode] = useState("")
  const [acknowledgeCheck, setAcknowledgeCheck] = useState(false)
  const [showGenerateEmailErrorMsg, setShowGenerateEmailErrorMsg] = useState(false)

  const [emailHasBeenSent, setEmailHasBeentSent] = useState(false)
  const [emailHasBeenCopied, setEmailHasBeenCopied] = useState(false)

  const [memberNotFound, setMemberNotFound] = useState(false)

const scrollViewRef = useRef(null);
const scrollEmailRef = useRef(null);
const scrollShareRef = useRef(null)

  async function getMp(){

   setTriggerGenerate(false)
   setEmailTemplate("")
   setCopyWithoutHTMLForEmail("")
   setRawEmailDataStore("")
    setEmailToSendTo("")
    setEmailHasBeentSent(false)

   const regex = /^((([a-zA-Z][0-9])|([a-zA-Z][0-9]{2})|([a-zA-Z]{2}[0-9])|([a-zA-Z]{2}[0-9]{2})|([A-Za-z][0-9][a-zA-Z])|([a-zA-Z]{2}[0-9][a-zA-Z]))(\s*[0-9][a-zA-Z]{2})$)/
   const confirmedPostcodeInput = regex.test(userLocation)
    //console.log(confirmedPostcodeInput)
    setConfirmedPostcode(confirmedPostcodeInput)    


    if(confirmedPostcodeInput === false){
      setConfirmedPostcode(false)
      //console.log("Oops, that does not seem like a postcode!")
    } else {
      
      setConfirmedPostcode(true)
      /**Google Tag Manager */
      window.dataLayer.push({
        event: 'event',
        eventProps: {
            // category: "interaction",
            // action: "input_postcode",
            // label: "postcodes",
            inputPostcode: userLocation
        }
      });
      
    /** Get the initial MP details */
    const response = await fetch(`
    https://members-api.parliament.uk/api/Location/Constituency/Search?searchText=${userLocation}&skip=0&take=20`);
  const mpList = await response.json();
    //console.log(mpList.items);
  setLocationResults(mpList.items)

  /**Check if member found (currentRepresentation is not null) */
if(mpList.items[0].value.currentRepresentation === null){
  setMemberNotFound(true)
} else {
  /**Check if name matches == check views does not return undefined */
  /**Get their views from the manual mpdata sheet */
  var mpViewsCheck = mpdata.find(item => item.mp === mpList.items[0].value.currentRepresentation.member.value.nameDisplayAs);
  //console.log("Check Views", "=>",mpViewsCheck)

  if(mpViewsCheck === undefined){
    setMemberNotFound(true)
  } else {
setCurrentMpViews(mpViewsCheck)

  const addEmailToResults = []
  /** then using the MP id get their contact details e.g. email */
  mpList.items.forEach(async mp => {
    const response = await fetch(
      `https://members-api.parliament.uk/api/Members/${mp.value.currentRepresentation.member.value.id}/Contact`,{
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers : {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const mpDetails = await response.json();

      /**leave as value[0] as that is specifically where the email data is per result */
  /** console.log("MP EMAIL", "=>" , mpDetails.value[0].email) */
  /**add email back into the object */
  mp.value.email = mpDetails.value[0].email
   
  /**add object into the new array */
  addEmailToResults.push(mp)
  })
  //console.log("RESULTS",addEmailToResults)
}//END OF MPVIEWS CHECK IF
}//END OF MP FOUND OR NOT CHECK
    }//END OF ELSE STATEMENT OF POSTCODE

        /**waits for element to be visible - if element is visible nothing to scroll to, then executes scrollintoview */
setTimeout(function(){

  const {current} = scrollViewRef
  if (current !== null){
    current.scrollIntoView({behavior: "smooth"})
  }},
  300)
    }//END OF GETMP FUNCTION

  function saveUserLocation(e){
    setUserLocation(e.target.value)
    //console.log(userLocation)
  }

  return (
    <div className="App">

      <div className='logoArea'>
      <a href="/"><img src={logo} alt="Plea for Palestine" className='logoStyle'></img></a>
      </div>
      <div className="headerArea">

    <div className='headlineStatement'>

      <div className='mainHeaderArea'>
    <h1 className='mainHeader'>SUPPORT PALESTINE</h1>
    
    <h2 className='secondHeader'><span className='currentFocus'>CURRENT FOCUS :</span> CALL FOR A CEASEFIRE NOW</h2>
    </div>
    <p className='headlineText'>
      USE OUR AI POWERED EMAIL CREATOR!
    </p>
    <p className='headlineText'>
      SIMPLY FILL IN YOUR DETAILS TO GENERATE A BESPOKE EMAIL TO SEND TO YOUR MP.
    </p>
    <p className='headlineText'>
      PLEASE CHECK BACK FOR FUTURE CAMPAIGNS.
    </p>
    </div>

      <div className='inputSearchArea'>

        <input className="userInput" placeholder="postcode" onChange={(e) => saveUserLocation(e)}></input>
        <button className="generateBtn" onClick={() => getMp()}><FaArrowRight /></button>
        </div>

        {
  confirmedPostcode === false ? <p className="postcodeErrorMsg">Oops, seems like that location is not recognised, please try again!</p> : null
}

{
  memberNotFound === true ?
  <p className="postcodeErrorMsg" id="findMpError">Sorry, we seem to be having an issue finding your MP!</p> : null
}
        </div>  
      

       {
       confirmedPostcode === true && memberNotFound === false ? <div className="allResults">
      <Block locationResults={locationResults} userLocation={userLocation} setEmailTemplate={setEmailTemplate}
      userFirstName={userFirstName} userFirstLineAddress={userFirstLineAddress}
      showGenerateEmailErrorMsg={showGenerateEmailErrorMsg} setShowGenerateEmailErrorMsg={setShowGenerateEmailErrorMsg}
      setUserFirstName={setUserFirstName} setUserFirstLineAddress={setUserFirstLineAddress}
      emailTemplate={emailTemplate} setCopyWithoutHTMLForEmail={setCopyWithoutHTMLForEmail}
      copyWithoutHTMLForEmail={copyWithoutHTMLForEmail}
      setEmailToSendTo={setEmailToSendTo} setTriggerGenerate={setTriggerGenerate} triggerGenerate={triggerGenerate}
      rawEmailDataStore ={rawEmailDataStore} setRawEmailDataStore={setRawEmailDataStore}
      subjectLine={subjectLine} setSubjectLine={setSubjectLine}
      currentMpViews={currentMpViews}
      scrollViewRef={scrollViewRef}
      scrollEmailRef={scrollEmailRef}
        setUserEmail={setUserEmail}
        userEmail={userEmail}
      emailHasBeenSent={emailHasBeenSent} setEmailHasBeentSent={setEmailHasBeentSent}
      emailHasBeenCopied={emailHasBeenCopied} setEmailHasBeenCopied={setEmailHasBeenCopied}
      />
      
{     triggerGenerate === true ?
      <EmailGeneration locationResults={locationResults} setEmailTemplate={setEmailTemplate}
      userFirstName={userFirstName} userFirstLineAddress={userFirstLineAddress}
      setUserFirstName={setUserFirstName} setUserFirstLineAddress={setUserFirstLineAddress}
      emailTemplate={emailTemplate} setCopyWithoutHTMLForEmail={setCopyWithoutHTMLForEmail}
      copyWithoutHTMLForEmail={copyWithoutHTMLForEmail} emailToSendTo={emailToSendTo}
      setEmailToSendTo={setEmailToSendTo}
      rawEmailDataStore ={rawEmailDataStore} setRawEmailDataStore={setRawEmailDataStore}
      acknowledgeCheck={acknowledgeCheck} setAcknowledgeCheck={setAcknowledgeCheck}
      subjectLine={subjectLine} setSubjectLine={setSubjectLine}
      scrollEmailRef={scrollEmailRef}
      userEmail={userEmail} 
      emailHasBeenSent={emailHasBeenSent} setEmailHasBeentSent={setEmailHasBeentSent}
      
      emailHasBeenCopied={emailHasBeenCopied} setEmailHasBeenCopied={setEmailHasBeenCopied}

      scrollShareRef={scrollShareRef}
      />
      : null
      }
      </div>
      :null
}

<div className="contactArea">
<p>For any enquiries or issues contact us at: <a href="mailto:contact@emailsforpalestine.com">contact@emailsforpalestine.com</a></p>
</div>
    </div>
  );
}

function EmailGeneration(props){

  const changeAcknowledge =() => {
    props.setAcknowledgeCheck(!props.acknowledgeCheck)
  }

  async function autoSend(){


    const response = await fetch("https://postemail-s5vonpyurq-uc.a.run.app/api/postEmail", {
      method: "POST",
      body: JSON.stringify({
        name: `${props.userFirstName}`,
        email: `${props.userEmail}`,
        mpEmail : `${props.emailToSendTo}`,
        subjectLine : `${props.subjectLine}`,
        mainBody : `${props.rawEmailDataStore}`
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    }).then(response=>response.text())
    .then(result=>{ 
      console.log(result)
    if(result === "success"){
      props.setEmailHasBeentSent(true)

      setTimeout(function(){
        const {current} = props.scrollShareRef
        if (current !== null){
          current.scrollIntoView({behavior: "smooth"})
        }},
        300)
    }
  })
    .catch((error) => {
      console.log(error)
    });

  }

  async function refreshSubjectLine(){

      /**Google Tag Manager */
      window.dataLayer.push({
        event: 'event',
        eventProps: {
            category: "interaction",
            action: "refresh_subject_line",
            label: "generate_email",
            value: 1
        }
      });

    props.setSubjectLine("")

     //get subject line
     fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_MAILJET_OPENAI
      },
      body: JSON.stringify({
        "model": "gpt-3.5-turbo-instruct",
        "prompt": `Generate 20 completely unique and different email subject lines about how angry you are with the Gaza Israel situation, add a comma at the end of each, remove numbers, bullet points and quotation marks.`,
       "max_tokens": 2049,
        "temperature": 0,
      }),
    }).then((response) => response.json())
    .then((data) => {
      //remove digits and quotes
      const formatResults = data.choices[0].text.replace(/[0-9]|['"]|[.]/g, '');
      //console.log(formatResults)
      //split into an array to randomize
      const putIntoArray = formatResults.split(",")
      const randomItem = putIntoArray[Math.floor(Math.random()*putIntoArray.length)];
      props.setSubjectLine(randomItem)
      //console.log(randomItem)
    });

  }

  function copyEmailFunction(){
    navigator.clipboard.writeText(props.rawEmailDataStore)
    props.setEmailHasBeenCopied(true)
  }
  
  return (
    <div className="generatedEmailArea" ref={props.scrollEmailRef}>
      
 { 
 props.emailTemplate === "" ? 
 <div className='loadingMsgContainer'>
 <p>An email is being generated to send to {props.emailToSendTo}</p>
 <div className="loadingCircle"></div>
</div>
  : 
  <div className="subjectAndEmailResults">
  <div className='subjectContainer'>
    <div className='subjectResults'>
  <p>Subject:{props.subjectLine}</p>
  </div>
  <div className='refreshSubject' onClick={() => {refreshSubjectLine()}}>
    {
  props.subjectLine === "" ?  <div className="loadingCircleSubjectLine"></div>
      :
  <MdRefresh/>
  }
</div>
</div>
  <div className="emailContainer">
    <div className='emailResults'>
       <div dangerouslySetInnerHTML={{ __html: props.rawEmailDataStore }}></div>
       </div>
       </div>
  </div>
       
       }

<div className='actionContainer'>
         <div className='acknowledgement'>
          <div className='acknowledgeCheckbox'>
         <Checkbox
         className='checkbox'
                onChange={changeAcknowledge}
              />
              </div>

          <div className="acknowledgeText">
          <p>By selecting this checkbox you acknowledge that you have read through the contents of the generated email, 
            are happy that it represents your views, and
            give consent for it to be emailed on your behalf to your MP.
          </p>
          </div>

         </div>


      
       <div className='actionBtns'>
          <div className='sendEmailContainer'>
       {
       
       props.acknowledgeCheck === false ?
       <p className='blockedBtn'>Send Email</p>
       :
       props.acknowledgeCheck === true && props.emailHasBeenSent === false ?
       <div className='sendEmailBox' onClick={() => autoSend()}>
          <p className='sendEmail'>Send Email</p>
          <MdEmail className="sendEmailVector"/>
          </div>

          : props.emailHasBeenSent === true ? 
        
          <div className='sentEmailBox'>
          <p className='emailHasBeenSent'>Email Sent.</p>
          </div>
          :
          null
}

          </div>
          <div className='copyEmailContainer'>
          {
       
       props.acknowledgeCheck === true ?
       <div className='copyEmailBox'>
     {   props.emailHasBeenCopied === false ?
        <p className='copyEmail'onClick={() => copyEmailFunction()}>Copy Email</p>
        :
        <p className='copyEmail'>Email Copied!</p>
        }
        <FaRegCopy />
        </div>
        :
        <p className='blockedBtn'>Copy Email</p>
          }
        </div>

        </div>

{
  props.emailHasBeenSent === true ? 
  <ShareBtns scrollShareRef={props.scrollShareRef}/>
  :
  <p>Please copy and send this email manually if you do not get a response from your MP.</p>
}

        </div>

    </div>
  )
}

function Block(props){

  

  const saveUserFirstName = (e) => {
    
    if(e.target.value === ""){
      props.setUserFirstName("A concerned member of the public")
    } else{
    props.setUserFirstName(e.target.value)
  }
   }
 
   const saveUserFirstLineAddress = (e) => {
    if(e.target.value === ""){
      props.setUserFirstLineAddress("")
    } else{
     props.setUserFirstLineAddress(e.target.value)
    }
   }

   const saveUserEmail = (e) => {
    if(e.target.value === ""){
      props.setUserEmail("")
    } else{
     props.setUserEmail(e.target.value)
    }
   }

  async function sendPrompt(email){

   //Check if first line address and or first name is empty - if it is filled in execute the following
    //Remember the user first name state is auto set to "a concerned member of the public" if the char length is reduced to 0

   if(props.userFirstLineAddress !== "" && props.userEmail !== ""){

      /**Google Tag Manager */
      window.dataLayer.push({
        event: 'event',
        eventProps: {
            mpConstituency : props.locationResults[0].value.name
            // category: "interaction",
            // action: "generate_email_request",
            // label: "generate_email",
            // value: 1
        }
      });

    props.setShowGenerateEmailErrorMsg(false)
    props.setEmailToSendTo(email)
    props.setEmailTemplate("")
    props.setEmailHasBeentSent(false)
    props.setTriggerGenerate(true)

    
let customPromptReturn = modularEmailFunction(props.currentMpViews, props.locationResults, 
  props.userFirstName, props.userFirstLineAddress, props.userLocation)

    //get subject line
    fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_MAILJET_OPENAI
      },
      body: JSON.stringify({
        "model": "gpt-3.5-turbo-instruct",
        "prompt": `Generate 20 completely unique and different email subject lines about how angry you are with the Gaza Israel situation, add a comma at the end of each, remove numbers, bullet points and quotation marks.`,
       "max_tokens": 2049,
        "temperature": 0,
      }),
    }).then((response) => response.json())
    .then((data) => {
      //remove digits and quotes
      const formatResults = data.choices[0].text.replace(/[0-9]|['"]|[.]/g, '');
      //console.log(formatResults)
      //split into an array to randomize
      const putIntoArray = formatResults.split(",")
      const randomItem = putIntoArray[Math.floor(Math.random()*putIntoArray.length)];
      props.setSubjectLine(randomItem)
      //console.log(randomItem)
    });


   //send email prompt and return email
    fetch("https://api.openai.com/v1/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_MAILJET_OPENAI
    },
    body: JSON.stringify({
      "model": "gpt-3.5-turbo-instruct",
      "prompt": customPromptReturn,
       "max_tokens": 2049,
      "temperature": 0,
    }),
  }).then((response) => response.json())
      .then((data) => {
        const rawEmailText = data.choices[0].text
        const encodeDataForEmail = data.choices[0].text.replace(/ /g, '%20').replace(/\n/g, '%0D%0A');
        const formatData = data.choices[0].text.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');
        //This is for users want to copy
        props.setRawEmailDataStore(rawEmailText)
        //This is to show in the browser
        props.setEmailTemplate(formatData)
        //This is to put in the email
        props.setCopyWithoutHTMLForEmail(encodeDataForEmail)

      });


      setTimeout(function(){
        const {current} = props.scrollEmailRef
        if (current !== null){
          current.scrollIntoView({behavior: "smooth"})
        }},
        300)
      } else {
        props.setShowGenerateEmailErrorMsg(true)
      }
        }

  return (
    <div className='resultsArea'>
    {
      props.locationResults.map(result => {
        return (
          
          <div className="resultsContainer" ref={props.scrollViewRef}>

          <div className="mpResultsContainer">

            <div  className={props.currentMpViews.ceasefire_vote === "Yes" ? "mpResultsBackgroundYes" : 
            props.currentMpViews.ceasefire_vote === "No" ? "mpResultsBackgroundNo" :
            "mpResultsBackgroundNo"}>
            <div className='mpResultsImg'>
            <img className="thumbnailFormatting"
             alt="Member of Parliament" src={result.value.currentRepresentation.member.value.thumbnailUrl}></img>
            

</div>
            <div className='mpResultsDetailsAndBtn'>
            <div className='mpResultsDetails'>
            <p className='detailsStyle' id="mpName">NAME : {result.value.currentRepresentation.member.value.nameDisplayAs}</p>
            <p className='detailsStyle' id="mpConstituency">AREA : {result.value.name}</p>
            <p className='detailsStyle' id="mpParty">PARTY : {result.value.currentRepresentation.member.value.latestParty.name}</p>
            
            <p className='ceasefireVote'>CEASEFIRE VOTE :&nbsp;
            {props.currentMpViews.ceasefire_vote}</p>
            </div>
            </div>
            </div>            
            </div>

            <div className='generateCustomEmailArea'>

              <h2 style={{width:"85%"}}>Input your name and address to generate a custom AI email</h2>

            <div className='nameInputArea'>
      <input className="userInputName" placeholder="Please insert your name" onChange={(e) => saveUserFirstName(e)}></input>
                   </div>

                   <div className='emailInputArea'>
      <input className="emailInput" placeholder="Please insert your email" onChange={(e) => saveUserEmail(e)}></input>
                   </div>
            
              <div className='addressInputArea'>
      <input className="addressInputFirstLine" placeholder="First line of your address" onChange={(e) => saveUserFirstLineAddress(e)}></input>
      <div className='generateEmailBtn' onClick={() => {sendPrompt(result.value.email)}}> 
      <FaArrowRight />
            </div>
              </div>

             { props.showGenerateEmailErrorMsg === true ?
             <div className='generateEmailError'>
                <p>Oops, looks like we are missing some information, please fill in all fields before clicking generate.</p>
                </div>
                : null
              }
              
              </div>

            </div>
        )
    })
      
    }
</div>
  )
}

export default App;
